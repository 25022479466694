<!--
  功能：主页
  作者：licy
  邮箱：licy@briup.com
  时间：2021年01月22日 16:41:17
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="home">
    <!-- 头部开始 -->
    <div class="header">
      <div class="header_contaner">
        <div class="header_left">
          <div>
            <img
              v-if="logo[0]"
              :src="logo[0].siteName"
            />
          </div>
          <div
            v-if="false"
            class="header_option"
          >
            <div @click="toCode">源码下载</div>
          </div>
        </div>
        <div class="header_right">
          <!-- 搜索框 -->
          <div class="search-box">
            <button
              class="search_button"
              @click="search_input"
            >
              <i
                class="iconfont icon-search"
                aria-hidden="true"
              ></i>
            </button>

            <input
              class="input"
              type="text"
              v-model="params.courseName"
              autocomplete="off"
              placeholder="搜索你需要的教程..."
            />
          </div>
          <!-- 登录 -->
          <!-- <div class="login">
            <span @click="toLogin('login')">登录</span>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 头部结束 -->

    <!-- 技术&轮播开始 -->
    <div class="tec">
      <el-carousel height="350px">
        <el-carousel-item
          v-for="item in carousel"
          :key="item.id"
        >
          <div class="gradient-wrap p4 mb2 mr1 inline-block">
            <img
              :src="item.carourselUrl"
              alt=""
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 技术&轮播结束 -->

    <!-- 导航栏开始 -->
    <div class="search">
      <div
        :class="active == index ? 'active' : ''"
        v-for="(item, index) in category"
        :key="item.id"
        @click="searchCourse(index, item.id, $event)"
      >
        {{ item.categoryName }}
      </div>
    </div>
    <!-- 导航栏结束 -->

    <!-- 内容区域开始 -->
    <div class="content">
      <!-- 教程列表 -->
      <div
        class="content_one"
        v-for="item in category_course"
        :key="item.id"
      >
        <div v-if="item.courseList ? item.courseList.length > 0 : ''">
          <h3 class="content_title">{{ item.categoryName }}</h3>
          <div
            v-for="course in item.courseList"
            :key="course.id"
            @click="toBook(course)"
          >
            <div
              class="content_list"
              v-if="course.courseOnline"
            >
              <div class="content_list_left">
                <img
                  v-if="course.courseLogoUrl"
                  :src="course.courseLogoUrl"
                />
                <img
                  v-else
                  src="../assets/404.jpg"
                  alt=""
                />
              </div>
              <div class="content_list_right">
                <p>{{ course.courseName }}</p>
                <p>{{ course.courseDesc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容区域结束 -->

    <!-- 底部开始 -->
    <div class="footer">
      <span v-if="copy_right[0]"><a
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >{{
          copy_right[0].siteName
        }}</a></span>
    </div>
    <!-- 底部结束 -->

    <!-- 回到顶部 -->
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import { get, post } from "@/http/axios";
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data() {
    return {
      // 目录
      category: [],
      // 目录 & 教程
      category_course: [],
      category_course_: [],
      copy_right: [],
      logo: [],
      active: 0,
      params: {
        pageNum: 1,
        pageSize: 10000
      },
      carousel: []
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    // 查询轮播图
    findCarousel() {
      get("/carousel/findAllCarousel").then(res => {
        this.carousel = res.data;
      });
    },

    // 搜索教程
    search_input() {
      let obj = {};
      get("/course/findCourseByPage", this.params)
        .then(res => {
          obj.courseList = res.data.list;
          obj.categoryName = "所有教程";
        })
        .then(() => {
          this.category_course = [obj];
        });
    },

    // 跳转到详情页
    toBook(val) {
      window.location.href = "https://docs.kaifagu.cn" + val.courseUrl;
    },

    // 跳转至源码下载页
    toCode() {
      window.location.href = "https://kaifagu.cn:3000/";
    },

    // 切换教程
    searchCourse(index, categoryId, e) {
      this.active = index;
      // 创建副本
      this.category_course = this.category_course_;

      // 非热门&全部 过滤
      this.category_course = this.category_course.filter(item => {
        return categoryId ? item.id == categoryId : item;
      });

      // 热门 过滤
      if (e.target.innerText == "热门教程") {
        let arr = [];
        let obj = {};
        get("/category/findCourseByPage?pageNum=1&pageSize=10000")
          .then(res => {
            arr = res.data.list.filter(item => {
              return item.courseHot == 1;
            });
          })
          .then(() => {
            obj.courseList = arr;
            obj.categoryName = "热门教程";
            this.category_course = [obj];
          });
      }
    },

    // 查询所有分类下的教程
    findAllCategory() {
      get("/category/findCategoryWithCourse").then(res => {
        this.category_course = res.data;
        this.category_course_ = res.data;
      });
    },

    // 查询可排序的分类
    findAllCateSort() {
      get("/category/findCategory").then(res => {
        res.data.unshift(
          { categoryName: "全部" },
          { categoryName: "热门教程" }
        );
        this.category = res.data;
      });
    },

    // 查询网站配置
    findSite() {
      get("/site/findAllSite").then(res => {
        this.copy_right = res.data.filter(item => {
          return item.siteCopyright == "copyright";
        });
        this.logo = res.data.filter(item => {
          return item.siteLogo == "系统logo";
        });
      });
    },

    // 登录页
    toLogin(data) {
      this.$router.push({ path: "/regLogin", query: { data: data } });
    }
  },
  // 以下是生命周期钩子
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    this.findSite();
    this.findAllCategory();
    this.findAllCateSort();
    this.findCarousel();
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style scoped>
/* 编目 */
.choice {
  margin: 0 auto;
  margin-top: 20px;
  width: 1100px;
  overflow: hidden;
}
.choice div {
  float: left;
  cursor: pointer;
  margin-right: 40px;
}
.active {
  background-color: #3eaf7c;
  border-radius: 4px;
  color: #fff;
}

/* 轮播模块 */
.el-carousel {
  height: 100% !important;
}
.tec {
  width: 1100px;
  margin: 0 auto;
  height: 400px;
  /* margin-top: 5px; */
}
.tec >>> .el-carousel__container {
  height: 100% !important;
}
.tec >>> .el-carousel__item img {
  height: 360px;
  width: 100%;
}

/* 导航栏 */
.search div {
  transition: all 0.24s;
  float: left;
  padding: 4px 12px;
  margin: 0 10px 14px 0;
  font-size: 15px;
  line-height: 1.5;
  cursor: pointer;
}
.search {
  width: 1100px;
  margin: 20px auto;
}

/* 头部样式开始 */
.home {
  height: 100%;
  width: 100%;
}
.header {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.header_contaner {
  width: 1100px;
  height: 60px;
  margin: 0 auto;
  background-color: #fff;
}
.header_left,
.header_left > div,
.header_option div {
  float: left;
}
.header_option div {
  margin-right: 20px;
  font-size: 14px;
  cursor: pointer;
}
.header_left .header_option {
  height: 60px;
  line-height: 60px;
}
.header_left img {
  height: 40px;
  margin-top: 10px;
}
.header_right > div {
  float: left;
}
.header_right {
  float: right;
}
.header_right .login {
  line-height: 60px;
  height: 60px;
  cursor: pointer;
  font-size: 14px;
}
.header_right .search-box {
  width: 160px;
  height: 30px;
  margin-top: 15px;
  margin-right: 30px;
  border: 2px solid #3eaf7c;
  border-radius: 4px 4px 4px 4px;
}
.search-box > * {
  float: right;
}
.search_button {
  height: 30px;
  width: 40px;
  border: none;
  background-color: #3eaf7c;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  margin-right: -2px;
}
.search_button i {
  color: #fff;
  font-size: 20px;
}
.input {
  width: 100px;
  height: 28px;
  font-size: 10px;
  border: none;
  margin-right: 10px;
  outline: none;
}
/* 头部样式结束 */

/* 尾部样式开始 */
.footer {
  background-color: #efefef;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: #999;
  font-size: 13px;
}
.footer a:hover {
  color: #777;
}
/* 尾部样式结束 */
/* 内容区域样式开始 */
.content {
  min-height: 800px;
}
.content_one {
  width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}
.content_list:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.4s;
  cursor: pointer;
}
.content_list {
  width: 255px;
  margin-right: 20px;
  height: 90px;
  background-color: #efefef;
  margin-bottom: 20px;
  float: left;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 13px;
}
.content_list_left {
  height: 100%;
  width: 64px;
  text-align: center;
  float: left;
}
.content_list_left img {
  width: 64px;
  height: 64px;
  display: inline;
}
.content_list_right {
  height: 100%;
  width: 60%;
  float: left;
  margin-left: 20px;
}
.content_list_right p:first-child {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  height: 23px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.content_list_right p:last-child {
  max-height: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;

  margin: 0;
  font-size: 13px;
  width: 100%;
  color: #666;
}
.content_title::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 12px;
  margin-bottom: 2px;
  vertical-align: middle;
  background-color: #3eaf7c;
}
</style>