import axios from 'axios'
import qs from 'qs'

// 配置
// 1. 基础路径
axios.defaults.baseURL = 'https://kaifagu.cn:39090'
// axios.defaults.baseURL = 'https://127.0.0.1:39090'

// 2. 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers['Authorization'] = localStorage.getItem('token');
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 3. 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  var res = response.data;
  return res;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

// 4. 封装方法
/**
  get方式请求
*/
export function get (url, params) {
  return axios({
    method: 'get',
    url,
    params, // get 请求时带的参数
    timeout: 10000
  })
}

/**
 * 提交post请求 发送的数据为formData【文件】
*/
export function post_media (url, data) {
  return axios({
    method: "post",
    url,
    data: data,
    timeout: 10000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 提交post请求 发送的数据为查询字符串，key=val&key=val
*/
export function post (url, data) {
  return axios({
    method: "post",
    url,
    data: qs.stringify(data),
    timeout: 10000
  })
}

/**
 * 提交post请求 ,查询字符串，对象中嵌套数组的格式
*/
export function post_obj_array (url, data) {
  return axios({
    method: "post",
    url,
    data: qs.stringify(data, { allowDots: true }),
    timeout: 10000
  })
}

/**
 * 提交post请求 发送的数据为查询字符串，当参数为数组的时候适用该方法
 * ids=1&ids=2
*/
export function post_array (url, data) {
  return axios({
    method: "post",
    url,
    data: qs.stringify(data, { arrayFormat: "repeat" }),
    timeout: 10000
  })
}
/**
 * 提交post请求 发送的数据为json字符串
*/
export function post_json (url, data) {
  return axios({
    method: "post",
    url,
    data,
    timeout: 10000
  })
}

export default axios